import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import store from "./store";
import Layouts from "./layouts";
import { THEME_CONFIG } from "./configs/AppConfig";
import "./lang";
import { AxiosConfigProvider } from "./auth/AxiosConfigProvider";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const environment = process.env.NODE_ENV;

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <AxiosConfigProvider>
            <ThemeSwitcherProvider
              themeMap={themes}
              defaultTheme={THEME_CONFIG.currentTheme}
              insertionPoint="styles-insertion-point"
            >
              <Layouts />
            </ThemeSwitcherProvider>
          </AxiosConfigProvider>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
