import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "set-password",
    path: `${AUTH_PREFIX_PATH}/setPassword`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/set-password")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgotPassword`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "default",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
    access: ["Admin"],
  },
  {
    key: "dashboard-client",
    path: `${APP_PREFIX_PATH}/dashboard-client`,
    component: React.lazy(() => import("../components/bep/DashboardClient")),
    access: ["Client"],
  },
  {
    key: "bets",
    path: `${APP_PREFIX_PATH}/bets`,
    component: React.lazy(() => import("../components/bep/Bets")),
    access: ["Admin"],
  },
  {
    key: "bets-client",
    path: `${APP_PREFIX_PATH}/bets-client`,
    component: React.lazy(() => import("../components/bep/BetsClient")),
    access: ["Client"],
  },
  {
    key: "bets-import",
    path: `${APP_PREFIX_PATH}/bets-import`,
    component: React.lazy(() => import("../pages/BetsImport")),
    access: ["Admin"],
  },
  {
    key: "users",
    path: `${APP_PREFIX_PATH}/users`,
    component: React.lazy(() => import("../components/bep/Users")),
    access: ["Admin"],
  },
  {
    key: "user-details",
    path: `${APP_PREFIX_PATH}/users/profile`,
    component: React.lazy(() => import("../pages/UserProfile")),
    access: ["Admin", "Client"],
  },
  {
    key: "user-new",
    path: `${APP_PREFIX_PATH}/users/new`,
    component: React.lazy(() => import("../pages/UserAdd")),
    access: ["Admin"],
  },
  {
    key: "user-edit",
    path: `${APP_PREFIX_PATH}/users/edit/:id`,
    component: React.lazy(() => import("../pages/UserEdit")),
    access: ["Admin"],
  },
  {
    key: "transactions",
    path: `${APP_PREFIX_PATH}/transactions`,
    component: React.lazy(() => import("../components/bep/Transactions")),
    access: ["Admin"],
  },
  {
    key: "transactions-client",
    path: `${APP_PREFIX_PATH}/transactions-client`,
    component: React.lazy(() => import("../components/bep/TransactionsClient")),
    access: ["Client"],
  },
];
