import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signOut } from "../store/slices/authSlice";
import { notification } from "antd";

export const AxiosConfigProvider = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const updateAuthToken = () => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      } else {
        delete axios.defaults.headers.common["Authorization"];
      }
    };

    axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
    axios.defaults.headers.common["Content-Type"] = "application/json";

    updateAuthToken();

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.response && error.response.status === 401) {
          await dispatch(signOut());
          navigate("/auth/login");
        } else if (error.response && error.response.status === 400) {
          const errorMsg = error.response.data.message;
          const description = errorMsg.message
            ? errorMsg.message
            : "Nieznany błąd";
          notification.error({
            message: "Błąd logowania",
            description: description,
          });
        } else if (error.response && error.response.status === 500) {
          const errorMsg = error.response.data.message;
          const description = errorMsg.message
            ? errorMsg.message
            : "Nieznany błąd";
          notification.error({
            message: "Błąd logowania",
            description: description,
          });
        } else if (!error.response) {
          const errorMsg = error.response.data.message;
          const description = errorMsg.message
            ? errorMsg.message
            : "Nieznany błąd";
          notification.error({
            message: "Błąd logowania",
            description: description,
          });
        }
        return Promise.reject(error);
      }
    );
  }, [navigate, token, dispatch]);

  return <>{children}</>;
};
